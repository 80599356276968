import React from 'react'
import {
  Container,
  Box,
  GridItem,
  VStack,
  Button,
  Center,
} from '@chakra-ui/react'
import { LayoutGrid, ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'
import { StickyFooter } from '@components'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useOnNavBack } from '@hooks'
import { findIndex } from 'lodash'
import { useUsersControllerV2GetCheckInScores } from '~/api/client'
import { DateTime } from 'luxon'
import { usePatientProfileStore } from '@core/store'
import { CheckInForm } from '@features'
import { CheckInScoreAnswerV1Dto, CheckInDtoV2, CheckInLikertNode, CheckInMultipleChoiceNode } from '~/api/client/models'

function buildAnswers(answers: CheckInScoreAnswerV1Dto[], checkIn: CheckInDtoV2) {
  return answers.reduce((map: any, answer) => {
    const node = checkIn.content.nodes.find((node) => node.id === answer.key) as (CheckInLikertNode | CheckInMultipleChoiceNode)
    if (node?.type === 'multiple_choice' || node?.type === 'likert') {
      if (node.answers.find((a) => a.id === answer.value) !== undefined) {
        map[answer.key] = answer.value
      } else {
        // @ts-ignore
        const valueAnswer = node.answers.find((a) => a.value?.toString() === answer.value?.toString())
        if (valueAnswer !== undefined) {
          map[answer.key] = valueAnswer.id
        } else {
          map[answer.key] = answer.value
        }
      }
    } else {
      map[answer.key] = answer.value
    }
    return map
  }, {})
}

export const CompletedCheckInView = () => {
  const { checkInId, checkInScoreId } = useParams()
  const { activeProfile } = usePatientProfileStore()

  const navigate = useNavigate()
  useOnNavBack(() => navigate(`/check-ins/${checkInId}/completed`))

  const { data } = useUsersControllerV2GetCheckInScores(activeProfile.userId, {
    checkInIds: checkInId ? [checkInId] : []
  }, { query: { enabled: Boolean(checkInId) }})

  if (!data) return null

  const { checkInModules, checkInScores } = data

  const checkInScore = checkInScores.find(score => score.id === checkInScoreId)
  const checkInModule = checkInModules.find(m => m.versionId === checkInScore?.versionId)

  const currentIndex = findIndex(checkInScores, s => s.id === checkInScore?.id)
  const nextScore = checkInScores[currentIndex + 1]
  const prevScore = checkInScores[currentIndex - 1]
  const answers = checkInScore ? buildAnswers(checkInScore.answers, checkInModule!) : {}

  if (!checkInModule) return null

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Box
              mb={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                as={Link}
                to={
                  prevScore
                    ? `/check-ins/${checkInModule.id}/completed/${prevScore.id}`
                    : '#'
                }
                disabled={!prevScore}
                variant="unstyled"
                borderRadius="50%"
                size="md"
                borderWidth="1px"
                borderColor="pale_gray"
              >
                <Center w="100%" h="100%">
                  <ArrowLeftIcon size="md" />
                </Center>
              </Button>
              <Box>
                {checkInScore
                  ? DateTime.fromISO(checkInScore.createdAt).toFormat(
                      'ccc L/dd/yy h:mm a'
                    )
                  : null}
              </Box>
              <Button
                as={Link}
                to={
                  nextScore
                    ? `/check-ins/${checkInModule.id}/completed/${nextScore.id}`
                    : '#'
                }
                disabled={!nextScore}
                variant="unstyled"
                borderRadius="50%"
                size="md"
                borderWidth="1px"
                borderColor="pale_gray"
              >
                <Center w="100%" h="100%">
                  <ArrowRightIcon />
                </Center>
              </Button>
            </Box>
            <VStack spacing="large">
              <CheckInForm checkIn={checkInModule} answers={answers} isDisabled />
            </VStack>
          </Box>
          <StickyFooter>
            <Button as={Link} to={`/check-ins/${checkInId}`} size="lg" w="100%">
              Check-in
            </Button>
          </StickyFooter>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
